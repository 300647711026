import React, { useState, useEffect } from "react";
import { Box, Button, Text, Flex, Heading, TextField } from 'gestalt';
import { eval_instr, criteria } from './JobDesConsts'

export const EvaluationCriterion = ({
    evaluationCriterion, setEvaluationCriterion, onComplete
}) => {
    const characterCount = 25;

    const [customCriterion, setCustomCriterion] = useState('');
    const [activeCriteria, setActiveCriteria] = useState({});

    const syncActiveCriteria = (allCriteria) => {
        // Start with default criteria
        const updated = criteria.reduce((acc, criterion) => {
            acc[criterion] = allCriteria.includes(criterion);
            return acc;
        }, {});

        // Mark any custom ones as active if they appear in evaluationCriterion
        allCriteria.forEach(criterion => {
            if (!criteria.includes(criterion)) {
                updated[criterion] = true;
            }
        });

        return updated;
    };

    useEffect(() => {
        setActiveCriteria(syncActiveCriteria(evaluationCriterion));
    }, [evaluationCriterion]);

    const toggleCriterion = (criterion) => {
        // Toggle the active status of the criterion
        setActiveCriteria(prev => {
            const newActiveCriteria = { ...prev, [criterion]: !prev[criterion] };
            // Update the evaluationCriterion based on toggles
            const updatedCriterion = Object.keys(newActiveCriteria).filter(key => newActiveCriteria[key]);
            setEvaluationCriterion(updatedCriterion);
            onComplete(updatedCriterion.length > 0);
            return newActiveCriteria;
        });
    };

    useEffect(() => {
        onComplete(evaluationCriterion.length > 0);
    }, [evaluationCriterion, onComplete]);

    // Add new custom criterion
    const handleAddCustomCriterion = () => {
        const trimmedCriterion = customCriterion.trim();
        if (
            trimmedCriterion &&
            trimmedCriterion.length <= 25 &&
            !evaluationCriterion.includes(trimmedCriterion)
        ) {
            const updatedList = [...evaluationCriterion, trimmedCriterion];
            setEvaluationCriterion(updatedList);
        }
        // Reset text field
        setCustomCriterion('');
    };

    const allCriteria = [
        ...new Set([...criteria, ...evaluationCriterion.filter((c) => !criteria.includes(c))])
    ];

    return (
        <Box display="flex" direction="column" width="100%" padding={3}>
            <Flex direction="row" justifyContent="start" alignItems="center" gap={50} padding={50}>
                <img src="/icons/logo.png" width="30" height="30" alt="STK Logo" title="STK" />
                <div style={{ marginRight: '10px' }}></div>
                <Heading>Evaluation Criterion</Heading>
            </Flex>

            <Box display="flex" direction="column" alignItems="start" paddingY={5}>
                <Text>
                    {`${eval_instr}`}
                </Text>
            </Box>

            <Flex justifyContent="center" wrap>
                {allCriteria.map((criterion, index) => (
                    <Box key={index} padding={2} width="33%" display="flex" justifyContent="center">
                        <Button
                            color={activeCriteria[criterion] ? 'blue' : 'gray'}
                            text={criterion}
                            onClick={() => toggleCriterion(criterion)}
                            size="lg"
                        />
                    </Box>
                ))}
            </Flex>
            <Box marginTop={7}>
            <Flex gap={3} alignItems="center" justifyContent="center">
                <Box marginEnd={3}>
                    <TextField
                        id="customCriterion"
                        helperText="Add your custom criteria (limit 25 chars)"
                        onChange={({ value }) => setCustomCriterion(value)}
                        value={customCriterion}
                        maxLength={{
                            characterCount,
                            errorAccessibilityLabel:
                                'Limit reached. You can only use 25 characters in this field.',
                        }}
                    />
                </Box>
                <Button
                    text="Add"
                    onClick={handleAddCustomCriterion}
                    disabled={!customCriterion.trim()}
                />
            </Flex>
            </Box>
        </Box>
    )
}